<template>
  <svg
    enable-background="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g id="background"><rect fill="none" height="32" width="32" /></g>
    <g id="view">
      <circle cx="16" cy="16" r="6" />
      <path
        d="M16,6C6,6,0,15.938,0,15.938S6,26,16,26s16-10,16-10S26,6,16,6z M16,24c-8.75,0-13.5-8-13.5-8S7.25,8,16,8s13.5,8,13.5,8   S24.75,24,16,24z"
      />
    </g>
  </svg>
</template>
