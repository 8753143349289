<template>
  <div
    v-if="isEmbed"
    class="
      bg-white
      dark:bg-dark-bgSecondary dark:border-dark-textSecondary
      w-full
      p-1
      h-80
    "
  >
    <div class="relative flex flex-col min-w-0 break-words w-full">
      <div class="flex-auto dark:text-dark-textPrimary pt-6">
        <div class="flex flex-col mb-7 lg:mb-8">
          <label
            class="
              block
              capitalize
              tracking-wide
              text-grey-mid text-xs
              md:text-lg
              font-semibold
              pt-2.5
            "
          >
            Type
          </label>
          <div class="mb-0">
            <ComboBox v-model="payload.type" :options="types" />
          </div>
        </div>
        <!-- 
        <div
          class="flex flex-col mb-7 lg:mb-8"
          v-show="payload.type.value !== 'OPEN'"
        >
          <label
            class="
              block
              capitalize
              tracking-wide
              text-grey-mid text-xs
              md:text-lg
              font-semibold
              pt-2.5
            "
          >
            Message
          </label>
          <div class="mb-0">
            <textarea
              v-model="payload.message"
              class="
                block
                w-full
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-lg
                border-none
                resize-none
                focus:border-none
                focus:outline-none
                focus:ring-2
                focus:ring-magenta
                rounded
                h-40
                py-2
                md:py-3
                px-2
                md:px-4
                mb-3
              "
            />
            <p v-if="payload.error" class="text-danger">
              {{ payload.error }}
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <ComboBox v-else v-model="payload.type" :options="types" />
</template>

<script>
import { reactive, watch } from "vue";

import ComboBox from "@/components/layouts/ComboBox";
import { empty } from "@/utils";
import { find } from "lodash";
export default {
  name: "ConcludeEvent",
  components: {
    ComboBox,
  },
  props: {
    initValue: [String],
    message: [String],
    isEmbed: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const types = [
      { label: "Pre-Event", value: "PRE" },
      { label: "Start Event", value: "OPEN" },
      { label: "Concluded Event", value: "CLOSED" },
    ];
    const initType = find(types, item => item.value === props.initValue);
    const payload = reactive({
      type: empty(initType) ? types[0] : initType,
      // message: props.message,
      error: "",
    });

    watch(
      () => payload.type,
      value => {
        if (!props.isEmbed) {
          emit("onChange", value);
        }
      },
    );

    return {
      payload,
      types,
    };
  },
  methods: {
    doSubmit() {
      // if (empty(this.payload.message) && this.payload.type.value !== "OPEN") {
      //   this.payload.error = "Please input message";
      //   return false;
      // } else {
      //   this.payload.error = "";
      //   return this.payload;
      // }
      return this.payload;
    },
  },
};
</script>
